<main>
    <!-- Banner -->
    <section id="section-banner">
        <div class="banner-content">
            <img id="banner-logo" src="../assets/images/logo-factory.png" alt="logo 4cadia" />
            <br>
            <h1>L3T’S BU1LD THE D3CENTR4L1ZED FUTUR3 T0GETH3R</h1>
        </div>
    </section>

    <!-- About -->
    <section id="section-about">
        <div class="about-content">
            <img class="symbol-c" src="../assets/images/symbol-group-c.svg" alt="symbol c" />
            <img class="symbol-a" src="../assets/images/symbol-group-a.svg" alt="symbol a" />
            <h1>About</h1>
            <p>
                4CADIA Factory is a blockchain focused software house that provide services for consulting and
                development of applications and systems, both on centralized and decentralized models.
            </p>
            <p>
                4CADIA factory has a group of software engineers, programmers and architects ready to supply the best
                solutions for your software development needs, constantly trained for proficiency in the most recent
                technologies.
            </p>
        </div>
    </section>

    <!-- Development -->
    <section id="section-development">
        <div class="development-content">
            <h2>Development</h2>
            <ul class="development-list">
                <li (click)="openModal('info-dapps')">
                    <div class="card-content" >
                        <img src="../assets/images/development/dapps.svg" alt="DApps" />
                        <h4>DApps and DAOs</h4>
                    </div>
                </li>
                <li (click)="openModal('info-webapp')">
                    <div class="card-content">
                        <img src="../assets/images/development/webapp.svg" alt="Web" />
                        <h4>Web Applications</h4>
                    </div>
                </li>
                <li (click)="openModal('info-smart')">
                    <div class="card-content">
                        <img src="../assets/images/development/smart.svg" alt="Smart" />
                        <h4>Smart Contracts</h4>
                    </div>
                </li>
                <li (click)="openModal('info-token')">
                    <div class="card-content">
                        <img src="../assets/images/development/token.svg" alt="Tokens" />
                        <h4>Tokens development</h4>
                    </div>
                </li>
                <li (click)="openModal('info-wallet')">
                    <div class="card-content">
                        <img src="../assets/images/development/wallet.svg" alt="Exchanges" />
                        <h4>Exchanges and Market Makers</h4>
                    </div>
                </li>
                <li (click)="openModal('info-gateways')">
                    <div class="card-content">
                        <img src="../assets/images/development/gateways.svg" alt="Gateways" />
                        <h4>Gateways</h4>
                    </div>
                </li>
                <li (click)="openModal('info-api')">
                    <div class="card-content">
                        <img src="../assets/images/development/api.svg" alt="API" />
                        <h4>API and Microservices</h4>
                    </div>
                </li>
                <li (click)="openModal('info-mobile')">
                    <div class="card-content">
                        <img src="../assets/images/development/mobile-dev.svg" alt="Hybrid" />
                        <h4>Hybrid Mobile Apps</h4>
                    </div>
                </li>
                <li (click)="openModal('info-algorithm')">
                    <div class="card-content">
                        <img src="../assets/images/development/algorithm.svg" alt="Data" />
                        <h4>Data and Algorithms</h4>
                    </div>
                </li>
                <li (click)="openModal('info-chatbots')">
                    <div class="card-content">
                        <img src="../assets/images/development/chatbots.svg" alt="Chatbots" />
                        <h4>Chatbots</h4>
                    </div>
                </li>
                <li (click)="openModal('info-ecommerce')">
                    <div class="card-content">
                        <img src="../assets/images/development/ecommerce.svg" alt="E" />
                        <h4>E-commerces</h4>
                    </div>
                </li>
                <li (click)="openModal('info-web3')">
                    <div class="card-content">
                        <img src="../assets/images/development/web3.svg" alt="Web3" />
                        <h4>Web3 Solutions</h4>
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <!-- Consulting -->
    <section id="section-consulting">
        <div class="consulting-content">
            <h2>Consulting</h2>
            <ul class="consulting-list">
                <li class="card">
                    <div class="card-content">
                        <img src="../assets/images/consulting/blockchain.svg" alt="Blockchain" />
                        <h3>Blockchain</h3>
                        <ul class="content-list">
                            <li>Development Training</li>
                            <li>Knowledge Consulting</li>
                        </ul>
                    </div>
                </li>
                <li class="card">
                    <div class="card-content">
                        <img src="../assets/images/consulting/software.svg" alt="Software" />
                        <h3>Software</h3>
                        <ul class="content-list">
                            <li>DevOps</li>
                            <li>Automation</li>
                            <li>Database Design</li>
                            <li>Architecture</li>
                            <li>Security</li>
                        </ul>
                    </div>
                </li>
                <li class="card">
                    <div class="card-content">
                        <img src="../assets/images/consulting/project.svg" alt="Project" />
                        <h3>Project</h3>
                        <ul class="content-list">
                            <li>Design and Discovery</li>
                            <li>Agile and Lean Managment</li>
                            <li>Quality Assurance</li>
                            <li>Outsourcing</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <!-- Process -->
    <section id="section-process">
        <div class="process-content">
            <h2>Process</h2>
            <ul class="process-text">
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_1.svg" alt="Frame 1" />
                    <h4>Architecture</h4>
                    <p>
                        First our architects research and document all requirements and constraints, use cases,
                        personas, processes and development models.
                    </p>
                </li>
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_2.svg" alt="Frame 2" />
                    <h4>Development</h4>
                    <p>
                        With agile methodology, development is broken down into small steps for accurate estimation and
                        customer quality assurance.
                    </p>
                </li>
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_3.svg" alt="Frame 3" />
                    <h4>Delivery</h4>
                    <p>Production delivery is carefully coordinated to ensure application quality and safety standards.
                    </p>
                </li>
            </ul>
            <div class="process-images"><img src="../assets/images/process.svg" alt="" /></div>
            <ul class="process-text">
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_4.svg" alt="Frame 4" />
                    <h4>Planning</h4>
                    <p>Development begins when engineers align what needs to be done and plan each requirement to be
                        delivered.</p>
                </li>
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_5.svg" alt="Frame 5" />
                    <h4>Implementation</h4>
                    <p>Each approved feature is packaged as a new increment of the application continuously and
                        automatically.</p>
                </li>
                <li>
                    <img class="mobile-images" src="../assets/images/process/Frame_6.svg" alt="Frame 6" />
                </li>
            </ul>
        </div>
    </section>

    <!-- Team -->
    <section id="section-team">
        <div class="team-content">
            <h2>Our Team</h2>
            <div class="team-list">
                <ul class="list-row">
                    <li class="item">
                        <img src="../assets/images/team/engineers.png" alt="engineers" />
                        <h4>Blockchain, Web and Full Stack Software Engineers.</h4>
                    </li>
                    <li class="item">
                        <img src="../assets/images/team/architects.png" alt="architects" />
                        <h4>Blockchain architects specializing in business architecture and solutions.</h4>
                    </li>
                </ul>
                <ul class="list-row">
                    <li class="item">
                        <img src="../assets/images/team/squads.png" alt="squads" />
                        <h4>Product oriented development squads</h4>
                    </li>
                    <li class="item">
                        <img src="../assets/images/team/network.png" alt="network" />
                        <h4>Associate Partner Network for Business Design and Development</h4>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <footer class="col-lg-12 text-center">
        <img class="m-2" src="../assets/images/logo_white.png" alt="4cadia logo" />

        <ul class="social-icons">
            <li>
                <a href="https://www.linkedin.com/company/4cad1a/" target="_blank">
                    <img src="../assets/icons/linkedin.svg" alt="linkedin icon" />
                </a>
            </li>
            <li>
                <a href="https://github.com/4cadia-foundation" target="_blank">
                    <img src="../assets/icons/github.svg" alt="github icon" />
                </a>
            </li>
            <li>
                <a href="https://twitter.com/4cadia?lang=en" target="_blank">
                    <img src="../assets/icons/twitter.svg" alt="twitter icon" />
                </a>
            </li>
            <li>
                <a href="https://medium.com/4cadia" target="_blank">
                    <img src="../assets/icons/medium.svg" alt="medium icon" />
                </a>
            </li>
        </ul>
    </footer>
</main>
<jw-modal id="popup-mouseleave">
    <div id="popupInfo" class="modal-dialog modal-xl">
        <div class="modal-content p-0 m-0">
            <button (click)="closeModal('popup-mouseleave')"
                class="close text-white fixed-top" data-dismiss="modal">&times;</button>
            <div class="modal-body p-0 m-0">
                <app-survey-page></app-survey-page>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-dapps">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-dapps')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>dApps and DAOs</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>dApps (decentralized applications) are a new technology for building web and mobile applications, where all the important log is processed by <b>Smart Contracs</b>, granting security and transparency to all data being processed.</p>
                        <p>DAOs (decentralized autonomous organizations) take this concept even further, where <b>whole systems</b> or even <b>whole organizations</b> can be operated and governed using <b>Smart Contracts</b>, without any central controller.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-webapp">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-webapp')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Web Applications</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Web applications are the current most used way to provide applications to users. These applications can be run by almost any browser, and <b>does not require any kind of instalation</b> to run.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-smart">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-smart')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Smart Contracts</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Smart contracts are special designed code that run in specific <b>Virtual Machines</b> emulated by blockchain transactions. These contracts are <b>completely tamper proof</b> due to its imutable code</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-token">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-token')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Tokens</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Tokens are virtual representations of value inside blockchains. They may relate to <b>any kind of asset</b>, depending on its token mechanics. These assets can be transfered freely without interference of any central authority.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-wallet">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-wallet')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Exchanges and Market Makers</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Exchanges are applications that connect <b>buyers and sellers</b> of assets. Often they require <b>Market Makers</b> to automatically handle orders to provide liquidity or conduct price movements.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-gateways">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-gateways')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Token Gateways</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Token Gateways are used to convert <b>fiat currency value</b> to tokenized assets, using providers for this service.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-api">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-api')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>API and Microservices</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>APIs are applications for <b>machine to machine</b> interactions, used for conencting systems and servers. The use of <b>Microservices</b> allow for optimised systems that connect tho each other.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-mobile">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-mobile')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Hybrid Mobile Apps</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Mobile applications are designed for being installed and run in mobile devices like smart phones, tablets and smart watches. Hybrid apps are able to run on <b>Android and iOS with the same codebase</b>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-algorithm">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-algorithm')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Data and Algorithms</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Handling Data and Algorithms allow for companies to <b>predict</b> and/or <b>optmize</b> processes and strategies, depending on the amount and quality of data gathered.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-chatbots">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-chatbots')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Chatbots</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>Chatbots ars applications that allow users to chat with an <b>automated interlocutor</b>, that may answer to pre-configured questions or even <b>AI based dialogs</b>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-ecommerce">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-ecommerce')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>E-commerces</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>E-commerces are applications for consumers to be able to purchase goods and services over the internet, <b>without the need to go to a phisical store</b>. Very common since the early 2000s, these applications have evolved to provide a set of amazing capabilities, like <b>offering curated products based on sonsumer profile</b>, handling <b>stock and promotions autonomously</b> and even managing the <b>logistics and payment</b> operations</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="info-web3">
    <div class="modal-dialog modal-md">
        <div class="modal-content p-0 m-0">
            <div class="modal-body p-0 m-0">
                <div class="card shadow border-0 p-0 m-0">
                    <div class="card-header justify-content-center" style="background-image: linear-gradient(to bottom right, #103c92, #071D49) ;">
                        <button type="button btn-right" (click)="closeModal('info-web3')"
                            class="close text-white" data-dismiss="modal">&times;</button>
                        <h2 class="text-center text-white"><b>Web3 Solutions</b></h2>
                    </div>
                    <div class="card-body text-center">
                        <p>The so-called web 3.0 is a natural evolution from the internet we have nowaday, where <b>you are in control of your own data</b>. Web 3.0 opens a whole new horizon of <b>new opportunities</b> for almost all industries that currently operate using internet-based services.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>