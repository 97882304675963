<div class="container">
    <div class="card shadow border-0">
        <div class="card-body text-center" style="background-color: #fff;">
            <div *ngIf="intro">
                <h4 class="text-center" style="color: #071D49;"><b>How to implement blockchain technology on your company?</b></h4>
                <hr style="width:75%;text-align:center">
                <h5 class="text-center m-2" style="color: #071D49;">This survey asks about you and your company</h5><br>
                <h5 class="text-center m-2" style="color: #071D49;">Based on your answers, we will suggest the best strategy for implementing blockchain technology on your company</h5><br>
                <h5 class="text-center m-2" style="color: #071D49;">The average response time is <b>2 minutes</b></h5><br>
                <button class="button btn-lg text-center m-2 p-2" style="background-color: #071D49;" (click)="start()"><b class="text-white">Take Survey</b></button><br>
                <h6 class="text-center m-2" style="color: #071D49;"><b>Your data will not be recorded, unless you allow so in the contact form at the end of this survey</b></h6><br>
            </div>
            <div *ngIf="!(intro||pre||result||finalForm)">
                <h4 class="text-center" style="color: #071D49;"><b>{{selectedElement.question}}</b></h4>
                <hr style="width:75%;text-align:center">
                <form [formGroup]="form">
                    <div data-toggle="buttons">
                        <div class="text-center" *ngFor="let option of selectedElement.options; let i = index">
                            <label class="btn btn-secondary btn-lg btn-block my-2" style="background-color: #fff;"><input formControlName="answers" type="radio" name="answers" [value]="i" [checked]="i==0"><b class="m-2" style="color: #071D49;font-size: large;">{{option.name}}</b></label>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-end">
                    <button class="button btn-lg m-4 p-2" style="background-color: #071D49;" (click)="next()"><b class="text-white">Next</b></button><br>
                </div>
            </div>
            <div *ngIf="pre&&!result">
                <h4 class="text-center" style="color: #071D49;"><b>Blockchain can be a game-changer technology for many industries</b></h4>
                <b style="color: #071D49;">Read out these articles to know more about it</b>
                <hr style="width:75%;text-align:center">
                <div class="col-md-12 justify-content-center">
                    <div class="row justify-content-center hideScroll" style="max-width: 100%; max-height: 800px; overflow-y: scroll;">
                        <ng-medium-feed [feedUrl]="feedUrl" [styles]="feedStyle"></ng-medium-feed>
                    </div>
                </div>
                <button class="button btn-lg text-center m-2 p-2" style="background-color: #071D49;" [routerLink]="['/']"><b class="text-white">Finish</b></button><br>
            </div>
            <div *ngIf="result&&pre">
                <h4 class="text-center" style="color: #071D49;"><b>What are the possible strategies?</b></h4>
                <hr style="width:75%;text-align:center">
                <div style="color: #071D49;">
                    <h4><b>Internal blockchain development team</b></h4>
                    <p>This strategy is more recomended when there are a set of external conditions and internal strengths that allow for building an internal dedicated team for blockchain development</p><br>
                    <h4><b>Development outsource</b></h4>
                    <p>This strategy is the more viable in most occasions, considering that blockchain development is still a rare know-how in most of the companies over all industries</p><br>
                </div>
                <button class="button btn-lg text-center m-2 p-2" style="background-color: #071D49;" (click)="finish()"><b class="text-white">Finish</b></button><br>
            </div>
            <div *ngIf="result&&!pre">
                <h4 class="text-center" style="color: #071D49;"><b>Result</b></h4>
                <hr style="width:75%;text-align:center">
                <div style="color: #071D49;">
                    <h4>Your company should definitely <b>{{resultStrategy}}!</b></h4><br>
                    <p>{{resultDescription}}</p>
                </div>
                <hr style="width:75%;text-align:center">
                <h4 class="text-center" style="color: #071D49;"><b>Do you know how to implement this strategy?</b></h4>
                <button class="button btn-lg text-center m-2 p-2 btn-block" style="background-color: #071D49;" (click)="final()"><b class="text-white">Find out</b></button>
                <button class="button btn-lg text-center m-2 p-2 btn-block" style="background-color: #071D49;" [routerLink]="['/']"><b class="text-white">Exit</b></button>
            </div>
            <div *ngIf="finalForm">
                <h4 class="text-center" style="color: #071D49;"><b>Contact form</b></h4>
                <hr style="width:75%;text-align:center">
                <div style="color: #071D49;">
                    <h4><b>Fill out this form and receive updates, invites for webinars, free e-books and more</b></h4><br>
                    <form [formGroup]="formContact" (ngSubmit)="send()">
                        <label>Name</label>
                        <input id="name" name="name" class="form-control" type="text" formControlName="name" [ngClass]="{ 'is-invalid': formContact.controls.name.invalid && formContact.controls.name.dirty, 'is-valid': formContact.controls.name.valid }">
                        <span class="text-danger" *ngIf="formContact.controls.name.invalid && formContact.controls.name.dirty">
                          <p *ngIf="formContact.controls.name.errors.required"> Name is required!</p>
                        </span>
                        <label class="mt-2">Company</label>
                        <input id="company" name="company" class="form-control" type="text" formControlName="company">
                        <label class="mt-2">Role</label>
                        <input id="role" name="role" class="form-control" type="text" formControlName="role">
                        <label class="mt-2">Email</label>
                        <input id="email" name="email" class="form-control" type="email" formControlName="email" [ngClass]="{ 'is-invalid': formContact.controls.email.invalid && formContact.controls.email.dirty, 'is-valid': formContact.controls.email.valid }">
                        <span class="text-danger" *ngIf="formContact.controls.email.invalid && formContact.controls.email.dirty">
                          <p *ngIf="formContact.controls.email.errors.required"> Email is required!</p>
                          <p *ngIf="formContact.controls.email.errors.pattern"> Email must be valid!</p>
                        </span>
                        <label class="mt-2">Phone</label>
                        <input id="phone" name="phone" class="form-control" type="text" formControlName="phone">
                        <div class="form-group form-check mt-2">
                            <input type="checkbox" class="form-check-input" id="terms" formControlName="terms">
                            <label class="form-check-label" for="terms">I accept the <a target="_blank" href="/assets/pdf/ToU.pdf">Terms of Use</a> and the <a target="_blank" href="/assets/pdf/PP.pdf">Privacy Policy</a></label>
                        </div>
                        <div class="form-group form-check mt-1">
                            <input type="checkbox" class="form-check-input" id="agree" formControlName="agree">
                            <label class="form-check-label" for="agree">I allow my data to be collected and stored</label>
                        </div>
                        <button type="submit" class="button btn-lg text-center m-2 p-2" [ngStyle]="{'background-color': formContact.invalid ? '#f6f6f6' : '#071D49'}" [disabled]="formContact.invalid"><b class="text-white">Send</b></button>
                        <div class="d-flex justify-content-center">
                            <re-captcha (resolved)="resolved($event)" siteKey="6Le6TuIZAAAAAJZOL4XIH2o4BIm-a0zSV1Z9VYtF" style="display: inline-block;"></re-captcha>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
